import React from "react"
import H2Header from "../components/H2Header"
import Layout from "../components/layout/Layout"
import { GridContainer, GridItemFullWidth } from "../components/layout/Grid"
import SEO from "../components/Seo"

const TermsOfService = () => {
  return (
    <Layout>
      <SEO title="Terms of Service" />
      <GridContainer>
        <GridItemFullWidth>
          <H2Header>Terms of Service</H2Header>
        </GridItemFullWidth>
      </GridContainer>
    </Layout>
  )
}

export default TermsOfService
